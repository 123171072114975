import React from 'react';

import Seo from '../components/Seo';
import Disqus from 'disqus-react';

const MessagesPage = () => {
  const title = 'Fan Board';
  const slug = '/messages';
  const description = 'A message board for JLP fans.';

  const disqusShortname = 'jeanlucponty';
  const disqusConfig = {
    url: 'https://ponty.com/messages',
    identifier: 'messages',
    title: 'Fan Board',
  };

  return (
    <div
      data-aos="fade-in"
      data-aos-delay="150"
    >
      <h1 className="page-header">Fan Board</h1>
      <div className="page-content">
        {/* Disqus setup */}
        <Disqus.DiscussionEmbed shortname={disqusShortname} config={disqusConfig} />
      </div>
      <Seo
        slug={slug}
        title={title}
        description={description}
      />
    </div>
  );
};

export default MessagesPage;
